@font-face {
  font-family: VerlagBold;
  src: url('./resources/Verlag-Bold.otf') format('opentype');
}
@font-face {
  font-family: VerlagBook;
  src: url('./resources/Verlag-Book.otf') format('opentype');
}
@font-face {
  font-family: VerlagLight;
  src: url('./resources/Verlag-Light.otf') format('opentype');
}

@font-face {
  font-family: VerlagBlack;
  src: url('./resources/Verlag-Black.otf') format('opentype');
}

.mat-ripple-element {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: VerlagBook, VerlagBold, VerlagLight, -apple-system,
    BlinkMacSystemFont, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: VerlagBook, VerlagBold, VerlagLight, -apple-system,
    BlinkMacSystemFont, sans-serif;
}
