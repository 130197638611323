@font-face {
  font-family: VerlagBold;
  src: url('../../../resources/Verlag-Bold.otf') format('opentype');
}
@font-face {
  font-family: VerlagBook;
  src: url('../../../resources/Verlag-Book.otf') format('opentype');
}
@font-face {
  font-family: VerlagLight;
  src: url('../../../resources/Verlag-Light.otf') format('opentype');
}

/* Mobile styles */
@media screen and (max-width: 480px) {
  .CalendarMonth_caption {
    padding-bottom: 45px;
  }
  .DayPicker_weekHeader {
    padding-top: 5px !important;
  }
  .DayPicker_portal__horizontal {
    top: 45%;
  }
}

.SingleDatePicker_picker__portal {
  z-index: 1400 !important;
}

/* Desktop styles */
@media screen and (min-width: 480px) {
  .DayPicker_transitionContainer {
    width: 355px !important;
  }

  .DayPicker_weekHeader_li {
    font-size: 16px;
    font-family: VerlagBook;
    margin: 0 2.5px 0 2.5px;
  }

  .CalendarMonth_table {
    border-spacing: 5px 0px;
    border-collapse: separate;
    margin-top: 7px;
  }

  .DayPicker {
    box-shadow: 0 0 4px 0 rgba(0 0 0 / 10%);
    width: 355px !important;
    z-index: 6;
    border: solid 1px #d8d8d8;
  }

  .SingleDatePicker_picker {
    width: 355px !important;
    z-index: 6;
    border-radius: 3px;
    margin-top: -14px;
    left: 0px !important;
  }

  .DayPicker_weekHeader {
    width: 355px !important;
  }

  .DayPicker_weekHeaders {
    margin-left: 0;
  }

  .DayPickerNavigation {
    width: 355px !important;
  }

  .CalendarMonthGrid {
    left: 0;
  }
}

/* Universal styles */
.DateInput_fang {
  width: 0;
  height: 0;
}

.CalendarMonth_caption {
  font-size: 18px;
  font-family: VerlagLight;
  font-weight: normal;
  color: black;
}

.DayPickerNavigation_button {
  color: #898989;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px;
  border: none;
  height: 12px;
  pointer-events: none;
}
/* .DayPicker_weekHeader_ul {
  padding-bottom: 10px;
} */

.DayPickerNavigation_button:hover {
  border: none;
}
.DayPickerNavigation_button:focus {
  border: none;
  outline: none;
}

.CalendarDay {
  border: none;
  font-family: VerlagBook;
  color: black;
  font-size: 18px;
  outline: none;
  position: relative;
  background: transparent;
}

.CalendarDay__selected {
  background: #003e3e;
  color: white !important;
  border-radius: 50%;
  border: none;
  outline: none;
}

.CalendarDay__selected:hover,
.CalendarDay__selected:active {
  background: #003e3e !important;
  color: white;
  border-radius: 50%;
  border: solid 2px #3f6d6e !important;
  outline: none;
}

.CalendarDay:hover {
  border: solid 1px #3f6d6e;
  border-radius: 50%;
  outline: none;
  background: transparent;
}

.CalendarDay:focus {
  color: #013e3f;
  border-radius: 50%;
  outline: 0;
}

.CalendarDay__blocked_calendar {
  border: none;
  outline: none;
}
.CalendarDay__blocked_calendar:hover {
  border: none;
  outline: none;
}
.CalendarDay__blocked_out_of_range {
  color: #767676;
}
.CalendarDay__blocked_out_of_range:hover {
  color: #767676;
}
.CalendarDay__outside {
  color: #767676;
  pointer-events: none;
  cursor: default;
}

.SingleDatePickerInput {
  border: none;
  width: 80% !important;
  padding-left: 10px;
}

.SingleDatePicker_closeButton {
  display: none;
}

.DateInput {
  background: transparent;
}

.DateInput_input {
  padding-left: 0;
  padding-top: 0;
  color: var(--clor, black);
  padding-bottom: 0;
  border: none;
  width: 170px;
  padding-right: 0;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
}

.SingleDatePickerInput {
  background: transparent;
}

.DateInput_input__disabled,
.DateInput__disabled,
.SingleDatePickerInput__disabled {
  background: #fff !important;
  font-style: normal;
}
