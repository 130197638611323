.mapboxgl-ctrl-group {
  height: 53px !important;
  width: 16px;
  border-radius: 4px !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25) !important;
  background-color: #ffffff;
  padding: 11px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: 'transparent' !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25) !important;
}

.mapboxgl-ctrl button:not(:disabled):focus {
  background-color: 'transparent' !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25) !important;
}

.mapboxgl-ctrl-group button {
  width: 32px !important;
  height: 32px !important;
}
